<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">成果報告書 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                @change="fetchList"
                v-model="search.year"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="seriesList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchShort inlineBlock searchSpace">
            <div>文理区分</div>
            <div>
              <b-form-select
                v-model="search.bunri"
                :options="bunriOption">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>報告者名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchShort inlineBlock searchSpace">
            <div>受付状況</div>
            <div>
              <b-form-select
                v-model="search.reptypeStatus"
                :options="reptypeStatusOption">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchShort inlineBlock">
            <div>ステータス</div>
            <div>
              <b-form-select
                v-model="search.status"
                :options="statusList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsReportListTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="repList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 link" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 link" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 link" @click="allSelect">全選択</p>
                <p class="mb-0 link" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedRepIdList($event,row.item.id)"
                :checked="selectedAppidList"
                :value="row.item.id"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(apptype_name)="row">
            {{row.item.apptype_name}} 報告
          </template>
          <template v-slot:cell(term)="row">
            <a
              @click="setModalDate(row.item)"
              class="viewMail bold">
            {{row.item.term}}</a>
          </template>
          <template v-slot:cell(statusText)="row">
            <span :class="[row.item.statusText==='確認中' ? 'red-text' : '']">{{row.item.statusText}}</span>
          </template>
          <template v-slot:cell(btn)="row">
            <b-link
              v-if="row.item.status !== status['UNSUBMIT'] && row.item.status !== status['TEMPSAVE']"
              :to="'/cms/reports/edit/'+row.item.repId">
              詳細
            </b-link>
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canReceipt || search.year === 'all'"
        @click="control(status['RECEIPT'])"
        >一括受領</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canExportPdf"
        @click="exportPdf()"
        >PDF出力</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-2"
        :disabled="selectedAppidList.length < 1"
        to="/cms/reports/mail">メール送信</b-button>
    </div>
    <b-modal
      :no-close-on-backdrop="true"
      id="reportsDateModal"
      ref="reportsSetDateModal"
      size="lg"
      title="提出期間設定"
      title-class="mailTitleModal">
      <div class="modalMailBody">
        <div class="user-text">
          <span class="mr-4">申請者氏名： {{modalData.name}}</span><span>申請番号： {{modalData.code}}</span>
        </div>
        <b-row class="justCenter">
          <b-col sm="8" class="flex flexMiddle justCenter">
            <v-form-calendar-datetime
              class="mt-2 pb-2"
              :noLabel='true'
              :noMinute='true'
              name="reportDate"
              @change="updateReportFrom"
              :value.sync="modalData.from"
              :hasErr="hasErr('from')"
              itemStyle="line"
              inputClass="w-small h-50px"
              ymdWrapClass="w-100px"
              :hasNull="true"
              :canPast="true"
              :isOpenSet="true"
              :noTime="true"
              @setOpenDate="setOpenDate"
              groupName="report" />
              <!-- :disabled="noInput" -->
            <span class="mr-4 ml-2">～</span>
            <v-form-calendar-datetime
              class="mt-2 pb-2"
              :noLabel='true'
              :noMinute='true'
              name="reportDate"
              @change="updateReportTo"
              :value.sync="modalData.to"
              :hasErr="hasErr('to')"
              itemStyle="line"
              inputClass="w-small h-50px"
              ymdWrapClass="w-100px"
              :hasNull="true"
              :canPast="true"
              :noTime="true"
              @ymdChange="ReportCloseSetYmd"
              :openDate="modalData.to && reportCloseYmd ? modalData.to : appReqOpenDate"/>
          </b-col>
        </b-row>
        <p
          v-for="(msg, index) in errors.from" :key="`from_${index}`"
          class="mb-0 error">
          {{msg}}
        </p>
        <p
          v-for="(msg, index) in errors.to" :key="`to_${index}`"
          class="mb-0 error">
          {{msg}}
        </p>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="dark" class="mr-4" @click="cancel()">
          キャンセル
        </b-button>
        <b-button size="sm" variant="primary" @click="reportDeadlineChange(modalData.id)">
          変更
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
import download from '@/modules/download';

export default {
  name: 'CmsApplicationsList',
  data() {
    return {
      search: {
        year: null,
        name: '',
        bunri: '',
        reptypeStatus: '',
        series: null,
        status: '',
      },
      status: CONST_STATUS.APP_STATUS,
      statusList: CONST_STATUS.CMS_REP_STATUS_OPTION_4_LIST,
      dbRepList: [],
      header: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '報告者名', sortable: true },
        { key: 'institution', label: '所属', sortable: true },
        { key: 'apptype', label: '助成プログラム', sortable: true },
        { key: 'bunri', label: '文理', sortable: true },
        { key: 'term', label: '提出期間', sortable: true },
        { key: 'reptypeStatus', label: '受付状況', sortable: true },
        { key: 'statusText', label: 'ステータス', sortable: true },
        { key: 'btn', label: '詳細', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      rawSeriesList: [],
      yearList: [],
      reptypeStatusOption: ['報告期間外', '受付中'],
      modalData: {
        id: '',
        name: '',
        code: '',
        from: '',
        to: '',
      },
      reportCloseYmd: null,
      appReqOpenDate: null,
      errors: {},
      pdfErr: null,
    };
  },
  methods: {
    setOpenDate(params) {
      if (params.group === 'report') {
        this.appReqOpenDate = null;
        if (params.value && params.value !== 'Invalid date') {
          this.appReqOpenDate = params.value;
        }
      }
    },
    ReportCloseSetYmd(param) {
      this.reportCloseYmd = param;
    },
    updateReportFrom(param) {
      const from = moment(param.value).format('YYYY/MM/DD 00:00:00');
      this.$set(this.modalData, 'from', from);
    },
    updateReportTo(param) {
      const to = moment(param.value).format('YYYY/MM/DD 23:59:59');
      this.$set(this.modalData, 'to', to);
    },
    async fetchList() {
      if (this.search.year !== null && this.search.year !== '' && this.search.year !== 'all') {
        if (this.search.year < 2025) {
          if (this.search.series === '学術調査研究助成') {
            this.search.series = '調査研究助成';
          }
          if (this.search.series === 'デジタルイノベーション社会実装助成') {
            this.search.series = '目的型調査研究助成';
          }
        } else {
          if (this.search.series === '目的型諸活動助成') {
            this.search.series = null;
          }
          if (this.search.series === '調査研究助成') {
            this.search.series = '学術調査研究助成';
          }
          if (this.search.series === '目的型調査研究助成') {
            this.search.series = 'デジタルイノベーション社会実装助成';
          }
        }
      }
      const param = { year: this.search.year };
      const response = await api.send('/api/cms/reports/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.dbRepList = response.data.list;
      this.yearList = response.data.yearList;
      if (this.search.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.rawSeriesList = response.data.seriesList;
    },
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const seriesSort = search.series === null || lineData.apptype === search.series || (search.series === '社会的文化的諸活動助成' && lineData.apptype === '社会的・文化的諸活動助成');
      const reptypeSort = search.reptypeStatus === '' || lineData.reptypeStatus === search.reptypeStatus;
      const bunriSort = search.bunri === '' || lineData.bunri === search.bunri;
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      return nameSort && seriesSort && bunriSort && reptypeSort && statusSort;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      // 編集ページ内での次の報告、前の報告に移動するためのIDリストの保存
      this.$store.commit('cmsReportSearch/initSelectedAppid');
      const submitedFilterList = filteredItems.filter((item) => {
        return item.status !== 0;
      });
      this.$store.commit('cmsReportSearch/setFilterdIdList', submitedFilterList);
      this.$store.commit('cmsReportSearch/setFilterdAppIdList', filteredItems);
    },
    async control(status) {
      const count = this.selectedRepIdList.length;
      let msg = `選択中の${count}件の報告のステータスを受領に変更します。`;
      msg += '\nステータスが変更されると、報告者にメールの送信と通知の登録が行われます。\n受領してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        status,
        selectedRepIdList: this.selectedRepIdList,
      };
      const response = await api.send('/api/cms/reports/control', params)
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('選択した成果報告を受領しました。', false);
      await this.fetchList();
      this.$store.dispatch('page/offWaiting');
    },
    setFilter(key, value) {
      this.$set(this.search, key, value);
    },
    allSelect() {
      this.$store.commit('cmsReportSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsReportSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdAppIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsReportSearch/addSelectedAppid', appId);
        const selectedRepInfo = this.dbRepList.filter((item) => {
          return item.appId === appId;
        });
        if (selectedRepInfo && selectedRepInfo[0].status !== 0) {
          const targetRepId = selectedRepInfo[0].repId;
          this.$store.commit('cmsReportSearch/addSelectedRepId', targetRepId);
        }
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdAppIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsReportSearch/removeSelectedAppid', appId);
        const selectedRepInfo = this.dbRepList.filter((item) => {
          return item.appId === appId;
        });
        if (selectedRepInfo && selectedRepInfo[0].status !== 0) {
          const targetRepId = selectedRepInfo[0].repId;
          this.$store.commit('cmsReportSearch/removeSelectedRepId', targetRepId);
        }
      });
    },
    updateSelectedRepIdList(list, appId) {
      const selectedRepInfo = this.dbRepList.filter((item) => {
        return item.appId === appId;
      });
      if (selectedRepInfo && selectedRepInfo[0].status !== 0) {
        const targetRepId = selectedRepInfo[0].repId;
        if (list.indexOf(appId) === -1) {
          this.$store.commit('cmsReportSearch/removeSelectedRepId', targetRepId);
        } else {
          this.$store.commit('cmsReportSearch/addSelectedRepId', targetRepId);
        }
      }
      this.$store.commit('cmsReportSearch/setSelectedAppid', list);
    },
    async exportPdf() {
      if (!this.canExportPdf) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const seriesData = this.seriesList.find(element => element.text === this.search.series);
      this.pdfErr = null;
      const params = {
        selectedRepIdList: this.selectedRepIdList,
        seriesId: seriesData.id,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/reports/export/pdf', params, requireBlob)
        .catch(async (err) => {
          const error = JSON.parse(await err.response.data.text());
          if (error && error.msg) {
            this.pdfErr = error.msg;
          }
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        if (this.pdfErr) {
          await this.alert(this.pdfErr);
        } else {
          await this.alert('ファイルのダウンロードに失敗しました。');
        }
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const fileName = `${ymd}_${this.search.series}.pdf`;
      download.blob(response.data, fileName);
    },
    setModalDate(param) {
      this.$set(this.modalData, 'id', param.id);
      this.$set(this.modalData, 'name', param.name);
      this.$set(this.modalData, 'code', param.code);
      this.$set(this.modalData, 'from', param.from);
      this.$set(this.modalData, 'to', param.to);
      this.reportCloseYmd = param.to;
      this.errors = {};
      this.$refs.reportsSetDateModal.show();
    },
    async reportDeadlineChange(id) {
      this.$store.dispatch('page/onWaiting');
      const params = {
        appId: id,
        params: JSON.stringify(this.modalData),
      };
      const response = await api.sendForm('/api/cms/reports/deadlineUpdate', params)
        .catch(async (error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.messages;
            await this.alert('入力内容に誤りがありました。');
          } else {
            await this.alert('保存に失敗しました。');
          }
          return false;
        });
      if (response === false) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('保存しました。', false);
      this.$refs.reportsSetDateModal.hide();
      await this.fetchList();
      this.$store.dispatch('page/offWaiting');
      // 絶対に再読み込みが必要。新規登録の場合、登録後に編集登録のトリガーになるapptypeIDを取得する必要があるので。
      // await this.fetchFormData();
    },
  },
  computed: {
    hasErr() {
      return (key) => {
        if (!this.errors[key]) {
          return false;
        }
        return true;
      };
    },
    bunriOption() {
      return ['文', '理'];
    },
    repList() {
      if (!this.dbRepList) {
        return [];
      }
      const repList = this.dbRepList.map((report) => {
        let institution = '';
        if ((report.name === '国際会議開催助成' || report.name === '社会的・文化的諸活動助成') && report.ans_apply_org.length > 0) {
          const applyOrg = report.ans_apply_org[0];
          institution = applyOrg.field_answer_text;
        } else {
          institution = report.university_name;
          if (!institution) {
            // eslint-disable-next-line
            institution = report.institution;
          }
        }
        return {
          id: report.appId,
          repId: report.repId,
          sort: report.sort,
          apptype: report.name,
          name: report.user_name,
          bunri: report.bunri,
          kana: report.user_name_kana,
          year: report.year,
          status: report.status,
          reptypeStatus: report.reptype_status,
          statusText: report.status_text,
          seriesId: report.series_id,
          term: `${report.from} ~ ${report.to}`,
          institution,
          code: report.code,
          from: report.db_from,
          to: report.db_to,
        };
      });
      return repList;
    },
    totalRow() {
      return this.repList.length;
    },
    adjustedApptypeData() {
      let optionDbData = this.rawSeriesList;
      if (this.search.year === 'all') {
        optionDbData = [];
        this.rawSeriesList.forEach((series) => {
          optionDbData.push(series);
          if (series.id === 2) {
            optionDbData.push({ id: series.id, name: '調査研究助成' });
          }
          if (series.id === 8) {
            optionDbData.push({ id: series.id, name: '目的型調査研究助成' });
          }
        });
      }

      return optionDbData;
    },
    seriesList() {
      const filterSeriesList = [];
      this.adjustedApptypeData.forEach((data) => {
        if ((this.search.year !== null && (this.search.year === 'all' || this.search.year < 2025)) || data.id !== 9) {
          filterSeriesList.push(data);
        }
      });
      const appTypeList = filterSeriesList.map((series) => {
        if (this.search.year !== null) {
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 2) {
            return { value: '調査研究助成', text: '調査研究助成', id: 2 };
          }
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 8) {
            return { value: '目的型調査研究助成', text: '目的型調査研究助成', id: 8 };
          }
        }
        return { value: series.name, text: series.name, id: series.id };
      });
      return appTypeList;
    },
    selectedRepIdList() {
      if (!this.$store.state.cmsReportSearch.selectedRepIdList) {
        return [];
      }
      return this.$store.state.cmsReportSearch.selectedRepIdList;
    },
    selectedAppidList() {
      if (!this.$store.state.cmsReportSearch.selectedAppidList) {
        return [];
      }
      return this.$store.state.cmsReportSearch.selectedAppidList;
    },
    filterdIdList() {
      return this.$store.state.cmsReportSearch.filterdIdList;
    },
    filterdAppIdList() {
      return this.$store.state.cmsReportSearch.filterdAppIdList;
    },
    canReceipt() {
      const number = this.selectedAppidList.length > 0;
      let isUnsubmit = false;
      this.selectedAppidList.forEach((appId) => {
        const selectedRepInfo = this.dbRepList.filter((item) => {
          return item.appId === appId;
        });
        if (selectedRepInfo.length > 0 && !isUnsubmit) {
          if (selectedRepInfo[0].status === 0 || selectedRepInfo[0].status === 5) {
            isUnsubmit = true;
          }
        }
      });
      return number && !isUnsubmit;
    },
    canExportPdf() {
      const { year, series } = this.search;
      // 選択があるか
      const number = this.selectedAppidList.length > 0;
      let isUnsubmit = false;
      this.selectedAppidList.forEach((appId) => {
        const selectedRepInfo = this.dbRepList.filter((item) => {
          return item.appId === appId;
        });
        if (selectedRepInfo.length > 0 && !isUnsubmit) {
          if (selectedRepInfo[0].status === 0 || selectedRepInfo[0].status === 5) {
            isUnsubmit = true;
          }
        }
      });
      return number && series && year !== null && !isUnsubmit && year !== 'all';
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  watch: {
    'search.year': async function () {
      this.$store.dispatch('page/onWaiting');
      await this.fetchList();
      this.$store.dispatch('page/offWaiting');
    },
  },
  // ロード画面
  async created() {
    await this.fetchList();
  },
};
</script>

<style>
  .cmsReportListTable thead th:nth-of-type(1) {
    width: 40px !important;
  }
  .cmsInterimListTable thead th:nth-of-type(5) {
    width: 70px !important;
  }
  .cmsReportListTable thead th:nth-of-type(7) {
    width: 120px !important;
  }
  .cmsReportListTable thead th:nth-of-type(8) {
    width: 80px !important;
  }
  .cmsReportListTable thead th:nth-of-type(9) {
    width: 80px !important;
  }
  #reportsDateModal___BV_modal_outer_{
    z-index: 999 !important;
  }
  #reportsDateModal .modal-footer{
    justify-content: center;
  }
</style>

<style scoped>

  .user-text{
    text-align: right;
    font-weight: bold;
  }
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
  .justCenter {
    justify-content: center;
  }
  p.error {
    color: #dc3545;
    display: block;
    width: 450px;
    margin: auto;
  }
</style>
